html,
body {
    overflow-x: hidden;
    position: relative;
    height: 100%;
}

body {
  margin: 0;
  font-family:'Helvetica', 'Arial', 'sans-serif';
  color:#212121;
}

#page-title-header-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0px 10px;
  font-size: 200%;
}

#svg-canvas-wrapper {
  overflow: hidden;
}

#svg-canvas {
  overflow: visible;
  z-index: -1;
  background-image: linear-gradient(45deg, #f0f0f0 25%, #f5f5f5 25%, #f5f5f5 50%, #f0f0f0 50%, #f0f0f0 75%, #f5f5f5 75%, #f5f5f5 100%);
  background-size: 56.57px 56.57px;
}

#svg-canvas:focus {
  outline: none;
}

#debug-rect {
  background-color: rgba(155, 255, 155, 0.2);
  height: 100%;
  width: 100%;
}

.autobook-today p {
  font-weight: bold !important;
}

.MuiPickersCalendar-week {
  margin: 2px 0
}

.hover-highlight-green:hover {
  background-color: #85ffcd !important;
}

.hover-highlight:hover {
  background-color: #FAFAFA;
}

@media (max-width: 600px) {
  .seat-info-panel {
    width: 100%;
  }
  .hide-on-mobile {
    display: none;
  }

  .MuiDialog-container .MuiDialog-paper {
    margin: 16px;
  }
}

.seat-info-panel {
  background-color: white;
  z-index: 1;
  position: absolute;
  height: 100%;
  top: 0;
  width: 400px;
  max-width: 80%;
  transition: all 0.5s ease-out;
  right: 0;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  border-left: 1px solid rgba(0,0,0,0.1);
}

@media (max-width: 600px) {
  .seat-info-panel {
    width: 100%;
    max-width: 100%;
  }
}

.seat-info-panel-in {
  transform: translateX(0%);
}

.seat-info-panel-out {
  transform: translateX(100%);
}

.zynq-pop-button {
  padding-left:15px;
  padding-right:15px;
  background-color:#3DD8BB;
  color:white;
  width:40%;
  letter-spacing: 2px;
  cursor:pointer;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 10px;
  text-align:center;
}

.zynq-pop-inverted {
  background-color:#fff;
  color:#3DD8BB;
  border:1px solid #3DD8BB;
}

.zynq-pop-disabled {
  background-color:#F87170;
  color:white;
  border:1px solid #F87170;
  cursor:not-allowed;
}

.searchInputBoxVisiblity .MuiInput-underline:before {
  content: none !important
}

.searchInputBoxVisiblity {
  max-width: 0;
  transition: all 0.5s;
}

.searchInputBoxVisiblity:focus-within {
  max-width: 200px;
}

.searchInputBox {
  margin-left:20px;
  margin-right:20px;
  font-family:'Google Sans',Roboto,Arial,sans-serif;
  font-size:18px;
  width:300px;
  box-shadow:rgba(0, 0, 0, 0.1) 0px 1px 4px 0px, rgba(0, 0, 0, 0.06) 0px 3px 10px 0px;
  border:0px;
  padding:5px;
  padding-left:10px;
  padding-right:10px;
  border-radius:25px;
  display:flex;
  align-items: center;
  flex-wrap: wrap;
  cursor: text;
}

.tagline {
  font-size:200%;
  color:#666666;
}

.explainer {
  text-align:center;
  line-height:170%;
  font-size:150%;
  color:#787878;
}
